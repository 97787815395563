






import { Component } from 'vue-property-decorator'

import ImageModuleUi from '../../../../../modules/Image/ui/Image.ui.vue'

import { AbstractAddon } from '../../../abstract/addon-abstract'

/**
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
@Component<ImageAddon>({
  name: 'ImageAddon',
  components: { ImageModuleUi }
})
export class ImageAddon extends AbstractAddon {}

export default ImageAddon
